import { REACT_APP_AMPLITUDE_API_KEY } from 'lily-env';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import type { ServerZoneType } from '@amplitude/analytics-types';

/**
 * @description Amplitude API key
 */
const amplitudeApiKey: string = REACT_APP_AMPLITUDE_API_KEY;

/**
 * @description Options for Amplitude initialization
 */
const options = {
    serverZone: 'US' as ServerZoneType,
};

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
    forceSessionTracking: true,
});

/**
 * @description Function to initialize Amplitude
 * @param {string} userId
 */

export const initializeAmplitude = (userId?: string) => {
    if (userId) {
        amplitude.add(sessionReplayTracking).promise.then(() => {
            amplitude.init(amplitudeApiKey, userId, options);
        });
    } else {
        amplitude.add(sessionReplayTracking).promise.then(() => {
            amplitude.init(amplitudeApiKey, options);
        });
    }
};

/**
 * @description Function to track an event
 * @param {string} eventName
 * @param {object} properties
 */
export const trackEvent = (eventName: string, properties?: object) => {
    amplitude.track(eventName, properties);
};

/**
 * @description Function to track user session
 * @param {string} userId - User ID for the session
 */
export const trackSession = (userId: string) => {
    // Initialize with user ID if not already initialized
    trackEvent('Session_Start', { timestamp: new Date().toISOString() });
    initializeAmplitude(userId);
};

/**
 * @description Function to destroy the Amplitude session and clean up
 */
export const destroyAmplitudeSession = (userId: string) => {
    // Set the user ID to null
    trackEvent('Session_End', { timestamp: new Date().toISOString() });
    initializeAmplitude(userId);
};
