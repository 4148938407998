import { combineReducers } from 'redux';
import { reducer as proxyProduct } from 'redux-state/ProxyProduct/reducers';
import { reducer as modals } from 'redux-state/modals/reducers';
import { reducer as oauth } from 'redux-state/oauth/reducers';
import { reducer as productDetails } from 'redux-state/pam/productDetails/reducers';
import { reducer as filterTags } from 'redux-state/pam/productFilter/reducers';
import { reducer as PAM } from 'redux-state/pam/searchDetails/reducers';
import { reducer as analyticsDashboard } from 'redux-state/analytics/reducers';
import { reducer as GlobalSnackbar } from 'redux-state/globalSnackbar/reducers';
import { reducer as DebugSnackbar } from 'redux-state/debugSnackbar/reducers';
import searchApi from './searchGallery/searchApi';
import { reducer as productCopy } from 'redux-state/productCopy/searchDetails/reducers';
import { reducer as productCopyUpdates } from 'redux-state/productCopy/productUpdates/reducers';
import { reducer as productCopyfilterTags } from 'redux-state/productCopy/productFilter/reducers';
import { reducer as productCopyProductDetails } from 'redux-state/productCopy/productDetails/reducers';
import catalogManagerSlice from './catalogManager/slice';
import integrationManagerSlice from './integrations/slice';
import subsidiaryStoreSlice from './subsidiary/slice';

const rootReducer = combineReducers({
    analyticsDashboard,
    DebugSnackbar,
    GlobalSnackbar,
    filterTags,
    modals,
    oauth,
    PAM,
    productDetails,
    proxyProduct,
    searchApi,
    productCopy,
    productCopyUpdates,
    productCopyfilterTags,
    productCopyProductDetails,
    catalogManager: catalogManagerSlice.reducer,
    integrationManager: integrationManagerSlice.reducer,
    subsidiariesStore: subsidiaryStoreSlice.reducer,
});

export default rootReducer;
