import { AppBar, Avatar, Badge, Button, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from '@mui/material';
import { cloneDeep, get, orderBy, uniqBy } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useDispatch } from 'react-redux';
import { memo, useEffect, useRef, useState } from 'react';
import { OpenFeature, EvaluationContext } from '@openfeature/web-sdk';

import analytics from 'helpers/analytics';
import LogoutIcon from 'assets/images/LogoutIcon';
import NavbarStyles from './Styles/NavbarClasses';
import { Retailer } from 'models/brands/brands';
import useBrand from 'helpers/useBrand';
import { v4 } from 'uuid';
import { isStageOnLocal } from 'helpers/env';
import { openModal } from 'redux-state/modals/actions';
import { MODAL_TYPES } from 'constants/modalTypes';
import { lilyJwt } from 'helpers/ParseJWT';
import { DebugSwitch } from 'components/DebugSnackbar/DebugSwitch';
import { REACT_APP_ENV_NAME } from 'lily-env';
import NavButtonList from './NavButtonList';
import { useSelector } from 'redux-state/store';
import { logout } from 'redux-state/oauth';
import { KeyboardArrowDown } from '@mui/icons-material';
import { catalogManagerState } from 'redux-state/catalogManager/actions';
import { destroyAmplitudeSession } from 'helpers/Amplitude/amplitude';
import { ISubsidiaryListItem } from 'v2/models/redux/auth/subsidiaryListStore';

const Nav = memo(() => {
    const { brandsContext, saveBrand } = useBrand();
    const { currentRetailer } = brandsContext;
    const [open, setOpen] = useState<boolean>(false);
    const JWT = lilyJwt();
    const email: string = JWT?.sub;
    const userID: number = JWT?.id;
    const { firstName, lastName, role, userName } = JWT || {};

    const { hasUnsavedChanges } = useSelector((state) => state.productCopyUpdates);
    const subsidiaryList = useSelector((state) => state.subsidiariesStore.subsidiaryList) ?? [];

    const uniqueBrands = uniqBy(subsidiaryList, 'code'); // duplicates check
    const sortedBrands = orderBy(uniqueBrands, 'name');
    const anchorRef = useRef<HTMLHeadingElement>(null);
    const classes = NavbarStyles();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        destroyAmplitudeSession('');
    };

    const handleCancel = () => {
        analytics.track(
            'Signed Out',
            {
                userName,
                userEmail: email,
                isCustomer: (email || '').toLowerCase().includes('@lily.ai'),
            },
            { timestamp: new Date().toISOString() }
        );
        analytics.reset();
        handleLogout();
        setOpen(false);
    };

    const handleSetRetailer = async (retailer: Retailer, close: { (): void; (): void }) => {
        const clone = cloneDeep(brandsContext);
        clone.currentRetailer = retailer;
        const newContext: EvaluationContext = {
            targetingKey: `${userID}`,
            email,
            retailer: clone.currentRetailer?.code,
            userName,
            userID: `${userID}`,
            role,
            lastName,
            firstName,
        };
        //set user in darkly
        await OpenFeature.setContext(newContext);
        saveBrand(clone);
        close();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // navigate to catalog manager when retailer changed
        navigate('/catalogmanager');

        // Reset redux state
        catalogManagerState.resetCatalogmanagerStates();
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false && anchorRef.current !== null) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const showBadge = !get(currentRetailer, 'id');

    const onRetailerSelect = (retailer: Retailer, close: { (): void; (): void }) => {
        const isStagingOnLocal = isStageOnLocal();
        if (!isStagingOnLocal || (isStagingOnLocal && currentRetailer.code !== 'terra')) return handleSetRetailer(retailer, close);

        dispatch(openModal(MODAL_TYPES.LOCAL_STAGING_RETAILER_SWITCH_WARN, { onSubmit: () => handleSetRetailer(retailer, close) }));
    };

    const handleRetailerSelect = (retailer: Retailer, close: { (): void; (): void }) => () => {
        if (hasUnsavedChanges) {
            dispatch(
                openModal(MODAL_TYPES.PRODUCT_COPY_UNSAVED_WARNING, {
                    onSubmit: () => onRetailerSelect(retailer, close),
                })
            );
            return;
        }

        onRetailerSelect(retailer, close);
    };

    return (
        <>
            <AppBar
                position="fixed"
                style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid rgba(232, 232, 237, 1)',
                }}
                sx={{ height: '80px', boxShadow: 0 }}
            >
                <Toolbar>
                    <Grid alignItems="center" container direction="row" justifyContent="space-between" pt={0.75} spacing={2}>
                        <Grid item pl={1} pt={2}>
                            <Link to="/">
                                <Avatar alt="Lily.ai" src="static/images/lily.png" sx={{ width: '75px', height: '45px' }} variant="square" />
                            </Link>
                        </Grid>
                        <Grid item xs>
                            <NavButtonList />
                        </Grid>
                        <Grid item>
                            {REACT_APP_ENV_NAME === 'development' && <DebugSwitch />}

                            <PopupState popupId="demo-popup-menu" variant="popover">
                                {(popupState) => (
                                    <>
                                        <Badge
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            className={classes.blinkingDot}
                                            color="error"
                                            invisible={!showBadge}
                                            sx={{ mb: 4, zIndex: 1500 }}
                                            variant="dot"
                                        ></Badge>
                                        <Button
                                            data-cy="menu-dropdown"
                                            endIcon={<KeyboardArrowDown />}
                                            variant="text"
                                            {...bindTrigger(popupState)}
                                            className={classes.dropDownButton}
                                        >
                                            {currentRetailer?.name || 'My Accounts'}
                                        </Button>

                                        <Menu {...bindMenu(popupState)} disableScrollLock={true}>
                                            {sortedBrands.map((brand: ISubsidiaryListItem) => (
                                                <MenuItem
                                                    className={brand.name === currentRetailer?.name ? classes.currentDropItem : classes.dropDownItem}
                                                    data-cy="subsidiary-item"
                                                    key={v4()}
                                                    onClick={handleRetailerSelect(brand, popupState.close)}
                                                >
                                                    <ListItemText>{brand.name}</ListItemText>
                                                </MenuItem>
                                            ))}
                                            <Divider />
                                            <MenuItem className={classes.dropDownItem} data-cy="signOut-btn" onClick={handleCancel}>
                                                <ListItemIcon>
                                                    <LogoutIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Logout</ListItemText>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )}
                            </PopupState>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
});

export default Nav;
