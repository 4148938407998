import { createSlice } from '@reduxjs/toolkit';
import { ISubsidiaryStore } from 'v2/models/redux/auth/subsidiaryListStore';

const initialState: ISubsidiaryStore = {
    subsidiaryList: undefined,
};

/**
 * @description subsidiary Slice & reducer
 */
const subsidiaryStoreSlice = createSlice({
    name: 'subsidiaryStore',
    initialState,
    reducers: {
        setSubsidiaries: (state: ISubsidiaryStore, action): void => {
            state.subsidiaryList = action.payload;
        },
    },
});

export default subsidiaryStoreSlice;
