import store from '../store';
import subsidiaryStore from './slice';
import { ISubsidiaryListItem } from 'v2/models/redux/auth/subsidiaryListStore';

/**
 * @description subsidiary State
 * @description define all the action inside subsidiaryState
 */
export const SubsidiaryState = {
    /**
     * @description Sets list of subsidiaries for user account
     * @param {number} source
     * ```
     * SubsidiaryState.setUserSubsidiaries([{...}, {...}])
     * ```
     */
    setUserSubsidiaries: (list: Array<ISubsidiaryListItem>): void => {
        store.dispatch(subsidiaryStore.actions.setSubsidiaries(list));
    },
};
