import { REACT_APP_MIDDLEWARE_API } from 'lily-env';

/**
 * @description API Router model
 */
export class APIRouter {
    version: string;
    path: string;
    base: string;

    constructor(path: string, version: string = '') {
        this.base = REACT_APP_MIDDLEWARE_API;
        this.version = version;
        this.path = path;
    }
}

interface IQueryParams {
    [key: string]: string;
}

/**
 * @description prepare request url for the API with params in the url
 * @param {APIRouter} route
 * @param {string[] | number[]}ids
 * @returns
 */
export function requestBuilder(route: APIRouter, ids: (string | number)[] = [], queryParams: IQueryParams = {}): string {
    const params = prepareParamId(ids);
    const queryParam = prepareQueryParams(queryParams);
    return route.base + `${route.version}` + route.path + params + queryParam;
}

/**
 * @description concat all the ids in with /
 * @param ids
 * @returns {string}
 */
function prepareParamId(ids: (string | number)[]): string {
    if (ids.length === 0) return '';
    return '/' + ids.map((id) => id).join('/');
}

/**
 * @description prepare query params from object
 * @param params
 * @returns {string}
 */
function prepareQueryParams(params: IQueryParams = {}) {
    if (Object.keys(params).length === 0) return '';
    const queries = new URLSearchParams(params);
    return '?' + queries;
}
