import { APIRouter } from './apiRouter';

export const Endpoints = {
    batchListById: new APIRouter('/pi-api-proxy/v2/ingestions'),
    productSearchList: new APIRouter('/pi-api-proxy/v2/products:search/synonyms'),
    partnersList: new APIRouter('/pi-connector-proxy/v1/subsidiaries'),
    addPartnerInfo: new APIRouter('/pi-connector-proxy/v1/partners'),
    tagSuggestions: new APIRouter('/search/tags/autocomplete'),
    productFiltersList: new APIRouter('/search/products/filters/tableFormatted'),
    batchOverview: new APIRouter('/pi-api-proxy/v2/exports/batches/metrics'),
    productPreview: new APIRouter('/pi-connector-proxy/v1/partners'),
    exportIngestion: new APIRouter('/pi-connector-proxy/v1/partners'),
    ingestionByDateRange: new APIRouter('/pi-api-proxy/v2/ingestionsByDateRange'),
    productSearchCount: new APIRouter('/pi-api-proxy/v2/products:search/count'),
    subsidiarySettings: new APIRouter('/config/subsidiary/details'),
    subsidiaryPreprocessing: new APIRouter('/pi-config-proxy/v1/preprocessing-configurations'),
    partnersConfig: new APIRouter('/pi-config-proxy/v1/subsidiaries'),
    publishIngestion: new APIRouter('/pi-api-proxy/v1/export-ingestions'),
    subsidiaryList: new APIRouter('/subsidiary/list'),
};
