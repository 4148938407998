import { PaletteColor, PaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        borderColor?: PaletteColor;
    }
    interface PaletteOptions {
        borderColor?: PaletteColorOptions;
        backgroundColors?: {
            light?: string;
            lightBlue?: string;
        };
    }
    interface PaletteOptions {
        borderColor?: PaletteColorOptions;
        backgroundColors?: {
            light?: string;
            lightBlue?: string;
        };
    }
}

const LilyGlobalTheme = createTheme({
    palette: {
        primary: {
            main: '#e03568',
            contrastText: '#ffffff',
            light: '#fff5f8',
            dark: '#2196f3',
        },
        secondary: {
            dark: '#11cb5f',
            main: '#3c4043',
        },
        borderColor: {
            main: '#dfdfdf',
            dark: '#ffc7d3',
            light: 'rgba(0, 0, 0, 0.52)',
        },
        background: {
            default: '#ffffff',
        },
        text: {
            primary: '#3c4043',
            secondary: '#81868a',
        },
        success: {
            main: '#58a672',
            light: '#ceecd8',
        },
        error: {
            main: '#ef5350',
        },
        warning: {
            main: '#fcd07e',
        },
        info: {
            main: '#ebf6f9',
            light: '#ebf6f9',
            dark: '#71acbb',
        },
        grey: {
            50: '#fafafa',
            100: '#666',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#dadada',
            500: '#dfdfdf',
            600: '#757575',
            700: 'rgba(0, 0, 0, 0.87)',
            800: 'rgba(0, 0, 0, 0.6)',
            900: '#212121',
            A100: '#f5f5f5',
            A200: '#eeeeee',
            A400: '#bdbdbd',
            A700: 'rgba(218, 218, 218, 0.85)',
        },
        backgroundColors: {
            light: '#fafafa',
            lightBlue: '#ebf6f9',
        },
    },
    typography: {
        fontFamily: 'proxima-nova',
        h2: {
            fontSize: '32px',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        h6: {
            fontSize: '14px',
            fontWeight: '600',
        },
        body1: {
            fontSize: '14px',
            fontWeight: '500',
        },
        body2: {
            fontSize: '16px',
            fontWeight: '500',
        },
        subtitle1: {
            fontSize: '13px',
            fontWeight: 'bold',
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },

    components: {
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#3c4043',
                    border: ['solid', 1, '#3c4043'],
                    gap: '1rem',
                    padding: '1rem',
                    borderRadius: '1rem',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },

        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                font-family: 'proxima-nova';
                font-style: normal;
                font-weight: 100;
                src: local('Proxima Nova Thin'), url('./assets/fonts/proximanova/proximanova-thin.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-style: normal;
                font-weight: 300;
                src: local('Proxima Nova Light'), url('./assets/fonts/proximanova/proximanova-light.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-style: normal;
                font-weight: 400;
                src: local('proxima-nova'), url('/assets/fonts/proximanova/proximanova-regular.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-style: normal;
                font-weight: 500;
                src: local('proxima-nova'), url('/assets/fonts/proximanova/proximanova-medium.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-weight: 600;
                font-style: normal;
                src: local('proxima-nova Semi Bold'), url('./assets/fonts/proximanova/proximanova-semibold.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-weight: 700;
                font-style: normal;
                src: local('proxima-nova Bold'), url('./assets/fonts/proximanova/proximanova-bold.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-weight: 800;
                font-style: normal;
                src: local('proxima-nova Extra Bold'), url('./assets/fonts/proximanova/proximanova-extrabold.otf') format('opentype');
            }
            
            @font-face {
                font-family: 'proxima-nova';
                font-weight: 900;
                font-style: normal;
                src: local('proxima-nova Bold'), url('./assets/fonts/proximanova/proximanova-black.otf') format('opentype');
            }
            
            
          `,
        },
    },
});

export default LilyGlobalTheme;
