export enum GlobalSnackbarSeverity {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export interface GlobalSnackbar {
    open: boolean;
    message: string;
    title?: string;
    severity: GlobalSnackbarSeverity;
    autoclose?: boolean;
}

export interface GlobalSnackbarProps {
    title?: string;
    message: string;
    severity: GlobalSnackbarSeverity;
    autoclose?: boolean;
}

export interface GlobalSnackbarInput {
    title?: string;
    message: string;
    autoclose?: boolean;
}
